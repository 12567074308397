import { axiosInstance } from "./axiosInstance";

export const getScoresTimeLine = (user) => axiosInstance.get(`stats/kss_scores_timeline`, { 
    params: { 
        user: user,
    },
});

export const getTrackerStats = (period, user, team) => axiosInstance({
    method: 'get',
    url: `stats/get_tracker_stat_period`,
    headers: { 'Content-Type': 'application/json' },
    params: {
        user: user || localStorage.getItem('user-id'),
        team: team || localStorage.getItem('team-id'),
        time_type: period,
    },
});

export const getUserSessionStats = (period, user, team) => axiosInstance({
    method: 'get',
    url: `stats/user_session_stat`,
    headers: {
        'Content-Type': 'application/json',
    },
    params: {
        user: user || localStorage.getItem('user-id'),
        team: team || localStorage.getItem('team-id'),
        period: period
    },
})

export const getTeamSessionStats = (period = "week", team) => axiosInstance({
    method: "get",
    url: `stats/team_session_stat`,
    headers: {
        'Content-Type': 'application/json',
    },
    params: {
        id: team || localStorage.getItem('team-id')
    }
})


export const createRate = (cardData) => axiosInstance.post(`score/create_by_user/`,
    {
        result: cardData.score.result,
        user: cardData.user,
        team: cardData.team,
        behavior: cardData.behavior,
        belong: cardData.belong,
        "session_team": localStorage.getItem("session-team")
    })

export const updateRate = (cardData) => axiosInstance.put(`score/${cardData.score.id}/`, {
    result: cardData.score.result,
    user: cardData.user,
    team: cardData.team,
    behavior: cardData.behavior,
    belong: cardData.belong,
    "session_team": localStorage.getItem("session-team")
})

export const getWeekDayStats = (user, team) => axiosInstance({
    method: 'get',
    url: `stats/user_kss_stat`,
    headers: { 'Content-Type': 'application/json' },
    params: {
        user: user || localStorage.getItem('user-id'),
        team: team || localStorage.getItem('team-id'),
    },
});


export const getMyProgress = (userId) => {
    if (userId) {
        return axiosInstance({
            method: 'get',
            url: `stats/my_progress?user_id=${userId}`,
        });
    } else {
        return axiosInstance({
            method: 'get',
            url: `stats/my_progress`,
        });
    }
};

export const getMyWeekProgress = () => axiosInstance({
    method: 'get',
    url: 'stats/user_week_progress'
})