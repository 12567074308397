import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import { getSessionHistory, getTeamInfo } from '../api/adminPanel';
import SessionCard from '../components/pages/sessionHistory/sessionCard';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { colors } from '../styles/globalStyles';
import { ReactComponent as DotDivider } from '../images/dot_divider.svg';
import SessionCreateModal from '../components/pages/adminPanel/sessionCreateModal';

const StyledBreadcrumbs = styled(Breadcrumbs)({
    '& a.light': {
        opacity: 0.4,
        color: colors.darkBlue,
    },

    '& a.transparent': {
        opacity: 0.4,
        color: colors.white,
    },

    '& a.current.light': {
        opacity: 1,
        color: colors.main,
    },

    '& a.current.transparent': {
        opacity: 1,
        color: colors.white,
    },
});

const List = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 17,
});

const EmptyMessage = styled(Typography)({
    alignSelf: 'flex-start',
    textAlign: 'center',
    fontSize: 21,
    opacity: 0.6,
});

const MessageWrap = styled('div')({
    width: '100%',
    flexGrow: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
});

const Header = styled(Typography)({
    fontSize: 28,
    paddingLeft: 15,
    fontWeight: 600,
});

const SessionHistory = () => {
    const { teamId, companyId } = useParams();
    const navigate = useNavigate();
    const [current, setCurrent] = useState({});
    const [history, setHistory] = useState([]);
    const [sessionModalData, setSessionModalData] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [breadcrumbsData, setBreadcrumbsData] = useState({});

    useEffect(() => {
        getSessionHistory(teamId)
            .then((r) => {
                const sessionCurrent = r.data.current;
                const sessionHistory = r.data.history;
                setCurrent(sessionCurrent);
                setHistory(sessionHistory);
            })
            .catch((err) => {
                console.error("Ошибка получения истории сессии", err);
            });
    }, []);

    useEffect(() => {
        getTeamInfo(teamId)
            .then((r) => {
                setBreadcrumbsData(r.data);
            })
            .catch((err) => {
                console.error("Ошибка получения информации о команде", err);
            });
    }, []);

    const handleClickCard = (e, data) => {
        e.preventDefault();
        setSessionModalData(data);
        setModalOpen(true);
    };

    function handleDeleteSession(id) {
        setHistory((prev) => prev.filter((session) => session.id !== id));
        setModalOpen(false);
    }

    return (
        <Layout link={`/admin/company/${companyId}/team/${teamId}`}>
            <StyledBreadcrumbs separator={<DotDivider style={{ width: 2, height: 2 }} />}>
                <Link className="light" onClick={() => navigate('/admin')}>
                    Админ панель
                </Link>
                <Link>{breadcrumbsData?.team?.name}</Link>
                <Link className="current">Сессии</Link>
            </StyledBreadcrumbs>
            {current.length > 0 ? (
                <>
                    <Header>Актуальные сессии</Header>
                    <List>
                        {current.map((sessionData) => (
                            <SessionCard data={sessionData} active={true} onClick={handleClickCard} />
                            ))}
                    </List>
                </>
            ) : null}
            {history.length > 0 ? (
                <>
                    <Header>Прошлые сессии</Header>
                    <List>
                        {history.map((sessionData) => (
                            <SessionCard data={sessionData} onClick={handleClickCard} />
                            ))}
                    </List>
                </>
            ) : null}
            {Object.keys(current).length === 0 && history.length === 0 ? (
                <MessageWrap>
                    <EmptyMessage>
                        Кажется, у этой команды не прошло еще ни одной сессии
                    </EmptyMessage>
                </MessageWrap>
            ) : null}
            <SessionCreateModal
                open={modalOpen}
                sessionData={sessionModalData}
                teamId={teamId}
                onClose={() => setModalOpen(false)}
                onDelete={handleDeleteSession}
            />
        </Layout>
    );
};

export default SessionHistory;
