import {styled, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {getTeamSessionStats, getUserSessionStats} from '../api/stats';
import Layout from '../components/Layout';
import ProgressSessionProgress from '../components/progressSessionProgress';
import ProgressTracker from '../components/progressTracker';
import ProgressTrajectory from '../components/progressTrajectory';
import {colors} from '../styles/globalStyles';
import { userForAdminPanelAtom } from '../recoil/atoms/userAtom';
import { useRecoilValue } from 'recoil';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';
import { useNavigate } from 'react-router-dom';

const Header = styled('header')({
    padding: 10,

    display: 'flex',
    alignItems: 'center',
    gap: 8,
});

const TypographyHeader = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '100%',

    '& span': {
        color: colors.main,
    },
});

const ProgressPage = () => {
    const [userStats, setUserStats] = useState([
        { session: 1 },
        { session: 2 },
        { session: 3 },
        { session: 4 },
        { session: 5 },
        { session: 6 },
        { session: 7 },
    ]);
    const [teamStats, setTeamStats] = useState([
        { session: 1 },
        { session: 2 },
        { session: 3 },
        { session: 4 },
        { session: 5 },
        { session: 6 },
        { session: 7 },
    ]);
    const [maxScore, setMaxScore] = useState(0);
    const userForAdminPanel = useRecoilValue(userForAdminPanelAtom);
    const adminUser = useRecoilValue(AdminUserAtom);
    const navigate = useNavigate();

    const fillStatsData = (stats, setStats, responseArr) => {
        if (responseArr.length < 7) {
            const newStats = Object.assign([], stats)
            responseArr.forEach((item) => {
                // setStats((prev) => {
                //     prev[item.session - 1] = item;
                //     return prev;
                // });
                newStats[item.session -1] =item
                setStats(newStats)
            });
        } else {
            setStats(responseArr);
        }
    };

    useEffect(() => {
        if (adminUser.isStaff && !userForAdminPanel.id) {
            navigate("/admin");
        }

        getUserSessionStats('week', userForAdminPanel.id, userForAdminPanel.teams?.[0].id)
            .then((response) => {
                fillStatsData(userStats, setUserStats, response.data.sessions);
                setMaxScore(response.data['max_score']);
            })
            .catch((err) => {
                console.error("Ошибка получения статистики сессии", err);
            });

        getTeamSessionStats('week', userForAdminPanel.teams?.[0].id)
            .then((response) => {
                fillStatsData(teamStats, setTeamStats, response.data.sessions);
                setMaxScore(response.data['max_score']);
            })
            .catch((err) => {
                console.error("Ошибка получения статистики сессии команды", err);
            });
    }, []);

    return (
        <Layout link={'/'}>
            <Header>
                <TypographyHeader>
                    <span>Мой</span> Прогресс
                </TypographyHeader>
            </Header>
            <ProgressTrajectory />
            <ProgressSessionProgress type={'user'} data={userStats} maxScore={maxScore} />
            <ProgressSessionProgress type={'team'} data={teamStats} maxScore={maxScore} />
        </Layout>
    );
};

export default ProgressPage;
