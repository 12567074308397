export const timeFormat = (nextSession) => {
    let date = new Date(nextSession);
    return date.toLocaleString([], { hour: '2-digit', minute: '2-digit', hour12: false });
};

export const timeFormatDiff = (nextSession, meetingDate) => {
    let timeNow = new Date();
    let nextSessionDate = new Date(nextSession);
    let nextSessionCompare = new Date(nextSession);
    nextSessionCompare.setDate(nextSessionDate.getDate() + 1)
    nextSessionCompare.setHours(0,0,0,0)
    let meeting = new Date(meetingDate);
    let date = timeNow > nextSessionCompare ? meeting : nextSessionDate
    return date.toLocaleString([], { hour: '2-digit', minute: '2-digit', hour12: false });
};

export const dateFormat = (nextSession) => {
    let date = new Date(nextSession);
    let localeDate = date
        .toLocaleString('ru', {
            month: 'long',
            day: 'numeric',
        })
        .split(' ');
    let day = localeDate[0];
    let month = localeDate[1];
    return day + ' ' + month[0].toUpperCase() + month.substring(1);
};

export const dateFormatDiff = (nextSession, meetingDate) => {
    let nextSessionDate = new Date(nextSession);
    let nextSessionCompare = new Date(nextSession);
    nextSessionCompare.setDate(nextSessionDate.getDate() + 1)
    nextSessionCompare.setHours(0,0,0,0)
    let meeting = new Date(meetingDate);
    let timeNow = new Date()
    let localeDate = timeNow > nextSessionCompare ? meeting : nextSessionDate
    localeDate = localeDate.toLocaleString('ru', {
        month: 'long',
        day: 'numeric',
    })
    .split(' ')
    let day = localeDate[0];
    let month = localeDate[1];
    return day + ' ' + month[0].toUpperCase() + month.substring(1);
};

export const onlyShortMonth = (dateISO) => {
    let date = new Date(dateISO);
    let localeDate = date.toLocaleString('ru', {
        month: 'short',
    });
    let month = localeDate[0].toUpperCase() + localeDate.substring(1);
    return month.slice(0, -1);
};

export const onlyYear = (dateISO) => {
    let date = new Date(dateISO);
    let localeDate = date.toLocaleString('ru', {
        year: 'numeric',
    });
    // let month = localeDate[0].toUpperCase() + localeDate.substring(1)
    return localeDate;
};

export const monthDiff = (d1, d2) => {
    let months;
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth();
    months += date2.getMonth();
    return months <= 0 ? 0 : months;
};
