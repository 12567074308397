import React, {useEffect, useState} from 'react';
import FeedbackTitle from './feedbackTitle';
import FeedbackReviews from './feedbackReviews';
import RootModal from "./modals/rootModal";
import {getBehaviorFeedback, getSessionHistory} from "../api/feedback";
import {styled} from "@mui/system";
import { useRecoilValue } from 'recoil';
import { userForAdminPanelAtom } from '../recoil/atoms/userAtom';

const FeedbackWrap = styled("div")({
    overflowY:"auto", overflowX:"hidden",
    height: "100%",
    display: "flex",
    flexDirection: "column",

})

const getDropdownData = (data) => {
  if (data.length > 0){
      return(
          data.map(item=>(
              {
                  name: item.session.name,
                  interval: item.session.interval
              }
          ))
      )
  }
}

const getSelectedSessionData = (data,selectedSession) => {
    if (data.length > 0 && selectedSession){
        return data.filter(item=> item.session.name === selectedSession.name)[0]
    }
}

const FeedbackModal = ({ behaviorData, background, open, setIsOpen }) => {

    const [feedbackData, setFeedbackData] = useState([]);
    const [selectedSession, setSelectedSession] = useState();
    const [sessionHistory, setSessionHistory] = useState([]);

    const [myFeedbackData, setMyFeedbackData] = useState([]);

    const userForAdminPanel = useRecoilValue(userForAdminPanelAtom);

    useEffect(() => {
    }, [selectedSession]);

    useEffect(() => {
        if (behaviorData?.id){
            getBehaviorFeedback(behaviorData.id, userForAdminPanel.id).then(r=>{
                setFeedbackData(r.data.colleges)
                setMyFeedbackData(r.data.my)
            })
            .catch((err) => {
                console.error("Ошибка получения данных о поведении", err);
            });

            getSessionHistory(behaviorData.id, userForAdminPanel.id).then(r=>{
                setSessionHistory(r.data)
            })
            .catch((err) => {
                console.error("Ошибка получения истории", err);
            });
        }
    }
    ,[behaviorData]);

    return (
        <RootModal open={open} onClose={() => setIsOpen(false)} color={background} style={{justifyContent: "flex-start"}}>
            <FeedbackWrap>
                <FeedbackTitle
                    behaviorData={behaviorData}
                    sessionHistory={sessionHistory}
                    background={background}
                    onClose={() => setIsOpen(false)}
                />
                <FeedbackReviews dropdownData={getDropdownData(feedbackData)}
                                 setSelectedSession={setSelectedSession}
                                 reviews={getSelectedSessionData(feedbackData, selectedSession)}
                                 myReviews={getSelectedSessionData(myFeedbackData, selectedSession)}
                                 />
            </FeedbackWrap>
        </RootModal>
    );
};

export default FeedbackModal;
