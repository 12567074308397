import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/index.css';
import { ThemeProvider } from '@mui/material';
import { theme } from './styles/globalStyles';
import {RecoilRoot} from "recoil";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <RecoilRoot>
                <App/>
            </RecoilRoot>
        </ThemeProvider>
    </React.StrictMode>,
);

window.addEventListener('load', async ()=>{
    if ('serviceWorker' in navigator) {
        try{
            await navigator.serviceWorker.register('/service-worker.js')
        }catch (e){
            console.error(e)
        }
    }
})
