import {atom, selector} from "recoil";
import { getUserData } from "../../api/auth";

const AdminUserSelector = selector({
    key: 'adminUserSelector',
    get: async () => {
        try {
            const response = await getUserData()
            return {
                isStaff: response.data.is_staff,
                appAdminPanel: response.data.app_admin_panel,
            }
        } catch (err) {
            
        }
    },
  })

export const AdminUserAtom = atom({
    key: "adminUser",
    default: AdminUserSelector
})
