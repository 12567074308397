import { axiosInstance } from "./axiosInstance";
import axios from "axios";
import {SERVER_URI_API} from "./urls";

export const authUser = (username, password) => axios.post(`${SERVER_URI_API}/auth/login/`, {
        username: username,
        password: password,
    },
    {headers: {
        'Content-Type': 'application/json',
    }}
)

export const getUserData = (userId) => {
    if (userId) {
        return axiosInstance.get(`auth/current_user/`, {
            params: {
                user_id: userId
            }
        })

    } else {
        return axiosInstance.get(`auth/current_user/`)

    }
};