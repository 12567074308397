import { Button, Chip, IconButton, styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { createSessionUser, updateSessionUser } from '../api/session';
import Bg from '../images/bg.png';
import { ReactComponent as DoubleArrow } from '../images/double_arrow_icon.svg';
import { ReactComponent as ArrowLeft } from '../images/left_arrow.svg';
import { ReactComponent as Tick } from '../images/tick.svg';
import { kssDataAtom, userForAdminPanelAtom, userInfoAtomArr, userKssDataForAdminPanelAtom, userKssTrackerDataForAdminPanelAtom, userSessionTeamAtom, userValuesAtom } from '../recoil/atoms/userAtom';
import { colors } from '../styles/globalStyles';
import RootModal from './modals/rootModal';
import { getKssData } from '../util/kssData';
import { getKssTrackerData } from '../util/kssTracker';
import { kssTrackerDataAtom } from '../recoil/atoms/trackerPageAtoms';

const ListItem = styled('div')(({ color }) => ({
    cursor: 'pointer',
    marginBottom: 4,
    padding: 17,
    minHeight: 72,

    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    background: colors.transparentMain,
    borderRadius: 23,

    '&.active': {
        background: `url(${Bg}), ${color}`,
        backgroundBlendMode: 'multiply',
        '& p': {
            color: colors.white,
        },
    },
}));

const BackButton = styled(IconButton)({
    position: 'absolute',
    left: 27,
    width: 30,
    height: 30,
    background: 'rgba(59, 122, 246, 0.14)',
    borderRadius: 10,
});

const TypographyListItem = styled(Typography)({
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '128%',
});

const StyledChip = styled(Chip)({
    marginTop: 6,
    background: 'rgba(59, 122, 246, 0.14)',
    borderRadius: 6,

    '&.active': {
        background: 'rgba(255, 255, 255, 0.28)',
        color: colors.white,
    },
});

const TickButton = styled(IconButton)({
    marginLeft: 10,
    width: 48,
    height: 48,
    background: colors.white,
    border: '2px solid rgba(59, 122, 246, 0.14)',
    borderRadius: 13,

    '&.active': {
        background: 'rgba(255, 255, 255, 0.14)',
        border: '2px solid rgba(59, 122, 246, 0.28)',
    },
});

const MainButton = styled(Button)({
    marginTop: 5,
    // marginBottom: 17,

    width: '100%',
    height: 72,
    gap: 10,

    background: colors.main,
    borderRadius: 23,

    fontWeight: 700,
    fontSize: 18,
    lineHeight: '100%',
    color: colors.white,
});

const Flex = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 10,
});

const Header = styled('div')({
    padding: 17,
    marginBottom: 17,
    display: 'flex',
    justifyContent: 'center',
    borderBottom: '0.33px solid rgba(0, 0, 0, 0.3)',
});

const RoundSteps = styled(Typography)({
    position: 'absolute',
    right: 17,

    width: 28,
    height: 28,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    background: 'rgba(255, 255, 255, 0.14)',
    borderRadius: 100,

    fontWeight: 400,
    fontSize: 10,
    lineHeight: '100%',
    color: colors.white,
});

const Title = styled(Typography)({
    lineHeight: 1,
    letterSpacing: "0.01rem",
    fontWeight: 800,
    fontSize: 34,
    textAlign: 'center',
    color: colors.darkBlue,
    marginLeft: 'auto',
    marginRight: 'auto',
});

const MySessionsKssChoice = ({ open, onClose, isUpdateModel }) => {
    const KSS = ['keep', 'stop', 'start'];
    const totalSteps = 2;

    const userInfo = useRecoilValue(userInfoAtomArr);
    const userValues = useRecoilValue(userValuesAtom);
    const userSessionTeam = useRecoilValue(userSessionTeamAtom);
    const userForAdminPanel = useRecoilValue(userForAdminPanelAtom);

    const [progressData, setProgressData] = useRecoilState(kssDataAtom);
    const [, setUserKssTrackerDataForAdminPanel] = useRecoilState(userKssTrackerDataForAdminPanelAtom);
    const [, setUserKssDataForAdminPanel] = useRecoilState(userKssDataForAdminPanelAtom);
    const [, setKssTrackerData] = useRecoilState(kssTrackerDataAtom);

    const [kss, setKss] = useState(KSS[0]);
    const [isActive, setIsActive] = useState(null);
    const [step, setStep] = useState(0);
    const [postKeep, setPostKeep] = useState(null);
    const [postStop, setPostStop] = useState(null);
    const [postStart, setPostStart] = useState(null);
    const [savedUserKss, setSavedUserKss] = useState([]);

    const [isRequsetProcessing, setIsRequsetProcessing] = useState(false);

    const cleanData = { keep: [], stop: [], start: [] };

    if (userValues) {
        userValues.map((item) => {
            if (item.is_positive) {
                item.behavior.map((beh) => {
                    cleanData.keep.push({ text: beh.title, id: beh.id });
                    cleanData.start.push({ text: beh.title, id: beh.id });
                });
            } else {
                item.behavior.map((beh) => {
                    cleanData.stop.push({ text: beh.title, id: beh.id });
                });
            }
        });
    }

    useEffect(() => {
        if (open) {
            const userKeep = progressData.find((userKssItem) => userKssItem.status === 'keep');
            const userStop = progressData.find((userKssItem) => userKssItem.status === 'stop');
            const userStart = progressData.find((userKssItem) => userKssItem.status === 'start');

            const keepIndex = cleanData.keep.findIndex((keepItem) => {
                return keepItem?.id === userKeep?.id
            });

            const stopIndex = cleanData.stop.findIndex((stopItem) => {
                return stopItem?.id === userStop?.id
            });
            
            const startIndex = cleanData.start.findIndex((startItem) => {
                return startItem?.id === userStart?.id
            });

            setSavedUserKss([keepIndex, stopIndex, startIndex]);

            setPostKeep(cleanData.keep[keepIndex]);
            setPostStop(cleanData.stop[stopIndex]);
            setPostStart(cleanData.start[startIndex]);
        }
    }, [open]);

    useEffect(() => {
        if (open) {
            setIsActive(savedUserKss[step]);
        }
    }, [step, open, savedUserKss]);

    const currentColors = {
        keep: {
            color: colors.purple,
            bg: 'linear-gradient(259.85deg, #24A3FF 0%, #9F24FF 75.61%)',
        },
        stop: {
            color: colors.pink,
            bg: 'linear-gradient(266.44deg, #CB3BAB 0%, #EF4F88 50.02%, #EFB94F 109.13%)',
        },
        start: {
            color: colors.lightGreen,
            bg: 'linear-gradient(267.39deg, #FFD952 -8.46%, #11D1A3 39.05%, #1198D1 92.96%)',
        },
    };


    const checkDisabled = () => {
        if (step === 0){
            return postKeep === null
        }

        if (step === 2){
            if (postKeep === null && postStop === null && postStart === null){
                return true
            }
        }

        if (isRequsetProcessing) {
            return true;
        }
        
        return false
    }

    const checkStep = (step) => {
        switch(step){
            case 0: return false
            case 1: return postStop === null
            case 2: return postStop === null
        }
    }

    const handleButtonAddValue = (index, item) => {
        setIsActive(index);
        if (kss === 'keep') {
            if (postKeep?.id === item?.id){
                setPostKeep(null)
                setIsActive(null)
            } else{
                setPostKeep(item);
            }
        }
        if (kss === 'stop') {
            if(postStop?.id === item.id){
                setPostStop(null)
                setIsActive(null)
            } else{
                setPostStop(item);
            }
        }
        if (kss === 'start') {
            if(postStart?.id === item.id){
                setPostStart(null)
                setIsActive(null)
            } else{
                setPostStart(item);
            }
        }
    };

    const handleBackButtonClick = () => {
        // If final step is 3, we need to get KSS[1] (second element) to go to step 2
        setKss(KSS[step - 1]);
        setStep(step - 1);
    };
    const handleMainButtonClick = () => {
        if (step < totalSteps) {
            setIsActive(null);
            setKss(KSS[step + 1]);
            setStep(step + 1);
        } else {
            let postData = {
                user_id: userInfo.userId,
                data: [
                    {
                        status: 'Keep',
                        session: userSessionTeam.id,
                        user: userInfo.userId,
                        team: userInfo.teamId,
                        behavior: postKeep?.id ?? null,
                        skip: !postKeep,
                    },
                    {
                        status: 'Stop',
                        session: userSessionTeam.id,
                        user: userInfo.userId,
                        team: userInfo.teamId,
                        behavior: postStop?.id ?? null,
                        skip: !postStop,
                    },
                    {
                        status: 'Start',
                        session: userSessionTeam.id,
                        user: userInfo.userId,
                        team: userInfo.teamId,
                        behavior: postStart?.id ?? null,
                        skip: !postStart,
                    },
                ]
            };

            const functionToExecute = isUpdateModel ? updateSessionUser : createSessionUser;

            setIsRequsetProcessing(true);
            functionToExecute(postData)
                .then(({ data }) => {
                    let kss = data.map(item => {
                        return {...item, id: item.behavior}
                    });

                    setProgressData(kss);
                    setUserKssTrackerDataForAdminPanel(kss);
                    
                    getKssTrackerData()
                        .then((kssTrackerData) => {
                            setKssTrackerData(kssTrackerData);
                        })
                        .catch((error) => {
                            console.error("Ошибка получения КСС трекера", error);
                        });

                    getKssData(userForAdminPanel.id)
                        .then((kssData) => {
                            setUserKssDataForAdminPanel(kssData);
                        })
                        .catch((error) => {
                            console.error("Ошибка получения КСС", error);
                        });

                    onClose()
                    setIsRequsetProcessing(false);
                })
                .catch((err) => {
                    console.error("Ошибка создания или обновления сессии", err);
                    setIsRequsetProcessing(false);
                });
        }
    };

    const breadCrumbsData = [
        {
            uri: '',
            label: 'Мои сессии',
        },
        {
            uri: '',
            label: 'Выбор следующего KSS',
        },
    ];

    const getKSSName = (name) => {
        switch(name){
            case "keep": return {name: "Продолжу", eng: "Keep", for: "на", week: " этой неделе"}
            case "stop": return {name: "Прекращу", eng: "Stop", for: "за", week: " эту неделю"}
            case "start": return {name: "Начну", eng: "Start", for: "за", week: " эту неделю"}
            default: return "error"
        }
    }

    return (
        <RootModal
            open={open}
            onClose={onClose}
            style={{ justifyContent: 'flex-start' }}
            breadcrumbsData={breadCrumbsData}
        >
            <Header>
                {step > 0 && (
                    <BackButton onClick={handleBackButtonClick}>
                        <ArrowLeft fill={colors.main} />
                    </BackButton>
                )}
                <Title>
                    <span style={{ color: currentColors[kss].color, textTransform: 'capitalize' }}>
                        {getKSSName(kss).eng}
                    </span>{' '}
                    {getKSSName(kss).for} <br />
                    {getKSSName(kss).week}
                </Title>
            </Header>
            <div style={{ paddingInline: 17 }}>
                {cleanData[kss].map((item, i) => (
                    <ListItem
                        key={i}
                        className={i === isActive ? 'active' : null}
                        color={currentColors[kss].bg}
                        onClick={() => handleButtonAddValue(i, item)}
                        style={{display: postKeep?.text === item.text && kss === 'start' ? 'none' : 'flex'}}
                    >
                        <div>
                            <TypographyListItem>{item.text}</TypographyListItem>
                            {progressData && progressData.filter(
                                (e) =>
                                    e.behavior_title === item.text &&
                                    e.status ===
                                        KSS[step].charAt(0).toUpperCase() + KSS[step].slice(1),
                            ).length > 0 ? (
                                <StyledChip
                                    label={`Current ${
                                        KSS[step].charAt(0).toUpperCase() + KSS[step].slice(1)
                                    }`}
                                    size="small"
                                    className={i === isActive ? 'active' : null}
                                />
                            ) : null}
                        </div>
                        <TickButton
                            className={i === isActive ? 'active' : null}
                            // onClick={() => handleButtonAddValue(i, item)}
                        >
                            {i === isActive && <Tick />}
                        </TickButton>
                    </ListItem>
                ))}
                <MainButton onClick={handleMainButtonClick} disabled={checkDisabled()}>
                    <Flex>
                        {step < totalSteps ? (
                            <>
                                Продолжить <DoubleArrow />
                            </>
                        ) : isRequsetProcessing ? (
                            'Сохранение...'
                        ) : (
                            'Сохранить'
                        )}
                    </Flex>
                    <RoundSteps>
                        {step + 1}/{totalSteps + 1}
                    </RoundSteps>
                </MainButton>
                {step < totalSteps && checkStep(step) ?
                <MainButton onClick={handleMainButtonClick}>
                    <Flex>
                            <>
                                Пропустить
                            </>
                    </Flex>
                    <RoundSteps>
                        {step + 1}/{totalSteps + 1}
                    </RoundSteps>
                </MainButton> : null}

            </div>
        </RootModal>
    );
};

export default MySessionsKssChoice;
