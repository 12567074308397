import { getCardsData } from "../api/session"

export const getKssData = async (user) => {
  try {
      let response;
  
      if (typeof user === "number") {
          response = await getCardsData(user);
      } else {
          response = await getCardsData();
      }
      
      if (response.data.length === 0) {
          return []
      }

      const getItemByStatus = (status) => response.data.filter(i => i.status === status)[0]
      const kssData = [getItemByStatus("keep"), getItemByStatus("stop"), getItemByStatus("start")]
      
      return kssData

  } catch {
      return [];
  }
}