import React from "react";
import {Button, Card, styled, Typography} from "@mui/material";
import Bg from "../../../images/bg.png";
import {colors} from "../../../styles/globalStyles";
import { useNavigate } from 'react-router-dom';

const CompanyCardWrap = styled("div")({
    borderRadius: 40,
    background: `url(${Bg}), linear-gradient(53.36deg, #3B4EF6 -32.52%, #3BC9F6 69.33%)`,
    padding: "17px 17px 22px 17px",
    display: "flex",
    flexDirection: "column",
    gap: 17,
})

const CompanyName = styled(Typography)({
    color: colors.white,
    fontSize: 24,
    lineHeight: "28.8px",
    marginTop: 10
})

const TeamsGridWrap = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: 10,
    rowGap: 17,
})

const TeamButton = styled(Button)({
    height: 70,
    width: "100%",
    borderRadius: 23,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: 4,
})


const TeamName = styled(Typography)({
    fontSize: 16,
    letter: "0.01em",
    color: "inherit"
})

const Project = styled(Typography)({
    fontSize:10,
    lineHeight: "13px",
    letter: "0.01em",
    color: "inherit",
    opacity: 0.6
})

const TeamsGrid = ({teamsArr, companyId}) =>{
    const navigate = useNavigate()
    const handleClick = (team) =>{
        navigate(`/admin/company/${companyId}/team/${team.id}`)
    }

    return (  <TeamsGridWrap>
        {
            teamsArr.map(team=>(
                <TeamButton variant={"transparentLight"} onClick={()=> handleClick(team)} key={team.project.name}>
                    <Project variant={"h3"}>
                        {team.project.name}
                    </Project>
                    <TeamName variant={"h2"}>
                        {team.name}
                    </TeamName>
                </TeamButton>
            ))
        }
    </TeamsGridWrap>)
}

const CompanyCard = ({data}) => {
    return(
        <CompanyCardWrap>
            <CompanyName variant={"h2"}>
                {data.name}
            </CompanyName>
            <TeamsGrid teamsArr={data.teams} companyId={data.id}/>
        </CompanyCardWrap>
    )
}


export default CompanyCard