import { styled, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getUserData } from '../api/auth';
import Layout from '../components/Layout';
import MySessionMyKss from '../components/mySessionMyKSS';
import MySessionsNextSession from '../components/mySessionsNextSession';
import MySessionsTopButton from '../components/mySessionsTopButton';
import MySessionsWeekProgress from '../components/mySessionsWeekProgress';
import ProgressBehaviors from '../components/progressBehaviors';
import { userInfoAtomArr, userSessionTeamAtom, userValuesAtom, kssDataAtom, userForAdminPanelAtom, userKssTrackerDataForAdminPanelAtom } from '../recoil/atoms/userAtom';
import { colors } from '../styles/globalStyles';
import { timeFormatDiff, dateFormatDiff } from '../functions/nextSessionFormat';
import { AdminUserAtom } from '../recoil/atoms/adminPanelAtoms';
import { useNavigate } from 'react-router-dom';

const TypographyMySessions = styled(Typography)({
    paddingInline: 10,

    fontWeight: 700,
    fontSize: 24,
    lineHeight: '100%',

    '& span': {
        color: colors.main,
    },
});

const WeekProgressAndNextSession = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    gap: 2,
    height: 217,

    '& > *': {
        width: '50%',
    },
});

const MySessions = () => {
    const [userSessionTeam, setUserSessionTeam] = useRecoilState(userSessionTeamAtom);
    
    const [userInfoArr, setUserInfoArr] = useRecoilState(userInfoAtomArr);
    const setUserValues = useSetRecoilState(userValuesAtom);
    
    const [timeHours, setTimeHours] = useState('');
    const [timeDate, setTimeDate] = useState('');
    const [isUpdateAvaliable, setIsUpdateAvaliable] = useState(false);
    const kssData = useRecoilValue(kssDataAtom);
    const adminUser = useRecoilValue(AdminUserAtom);

    const userForAdminPanel = useRecoilValue(userForAdminPanelAtom);
    const userKssForAdminPanel = useRecoilValue(userKssTrackerDataForAdminPanelAtom);

    const navigate = useNavigate();
    

    useEffect(() => {       
        if (adminUser.isStaff && !userForAdminPanel.id) {
            navigate("/admin");
        }

        let userData = {};
        getUserData(userForAdminPanel.id || null)
            .then(({ data }) => {
                userData = {
                    username: data.username,
                    userId: data.id,
                    teamName: data.team.name,
                    teamId: data.team.id,
                    isStaff: data.is_staff,
                };
                setUserInfoArr((oldUserInfo) => ({ ...oldUserInfo, ...userData }));
                setUserValues(data.values);
                setUserSessionTeam(data.session);
                localStorage.setItem('session-team', data.session.id);
                setUserSessionTeam(data.session);
                setTimeHours(timeFormatDiff(data.session.next_date, data.session.meeting_date));
                setTimeDate(dateFormatDiff(data.session.next_date, data.session.meeting_date));
            })
            .catch((error) => {
                console.error("Ошибка получения пользователя", error);
            });;
}, []);

    useEffect(() => {
        const updated_at = (adminUser.isStaff ? userKssForAdminPanel : kssData).find((kssItem) => kssItem.updated_at)?.updated_at

        if (+updated_at?.slice(8, 10) === (new Date().getDate())) {
            setIsUpdateAvaliable(true);
        }
    }, [kssData, userKssForAdminPanel, adminUser]);

    return (
            <Layout link={'/'}>
                <TypographyMySessions>
                    <span>Сессии </span>с командой
                </TypographyMySessions>

                {!adminUser.isStaff && <MySessionsTopButton />}
                {(adminUser.isStaff ? userKssForAdminPanel : kssData).length === 0 ? <MySessionsTopButton KSS={true} /> : null}

                {(adminUser.isStaff ? userKssForAdminPanel : kssData).length !== 0 
                && isUpdateAvaliable ? <MySessionsTopButton KSS={true} update={true} /> : null}
                
                <WeekProgressAndNextSession>
                    <MySessionsWeekProgress />
                    <MySessionsNextSession
                        link={userSessionTeam.meeting_link}
                        hours={timeHours}
                        date={timeDate}
                        />
                </WeekProgressAndNextSession>
                <MySessionMyKss />
                <ProgressBehaviors/>
            </Layout>
    );
};

export default MySessions;
