import { axiosInstance } from "./axiosInstance";

export const getRates = () => axiosInstance.get(`score/`, {
    params: {
        user: localStorage.getItem("user-id"),
        team: localStorage.getItem("team-id")
    }
})

export const getRatesByBehavior = (behavior, belong) => axiosInstance.get(`score/get_by_behavior_belong`, {
    params: {
        belong: belong,
        behavior: behavior
    }
})

export const getScoreStats = () => axiosInstance.get(`stats/kss_scores`)

export const getAllTimeAvgFeedbackRates = ({userId}) => axiosInstance.get('score/all_time_avg',{
    params:{
        userId
    }
})

export const createScore = (belong, behavior, result) => axiosInstance({
    method: "POST",
    url: `score/create_score/`,
    data:{
        belong: belong,
        behavior: behavior,
        result: result
    }
})

export const updateScore = (score, belong, behavior, result) => axiosInstance({
    method: "PUT",
    url: `score/${score}/`,
    data:{
        belong: belong,
        behavior: behavior,
        result: result
    }
})

export const deleteScore = (score) => axiosInstance({
    method: "DELETE",
    url: `score/${score}/`,
})