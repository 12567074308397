import React from 'react';
import {styled} from '@mui/system';
import {Typography} from '@mui/material';
import {colors} from '../styles/globalStyles';

const Layout = styled('div')(({background}) => ({
    width: '100%',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    background,
    borderRadius: 23,

    overflow: 'hidden',
}));

const TypographySection = styled('section')({
    padding: '17px 0 10px 17px',
});

const TypographyOpaque = styled(Typography)({
    fontWeight: 500,
    fontSize: 12,
    lineHeight: '150%',
    letterSpacing: '0.01em',
    color: colors.white,
    opacity: 0.6,
    textTransform: 'capitalize',
});

const TypographyPercent = styled(Typography)({
    fontWeight: 700,
    fontSize: 24,
    lineHeight: '129%',
    letterSpacing: '0.01em',

    color: colors.white,
});

const StatLines = styled('section')({
    height: '100vh',
    display: 'flex',
    alignItems: 'flex-end',

    gap: 2,
});

const StatLine = styled('div')({
    content: '""',
    width: '20%',
    borderRadius: '7px 7px 0px 0px',
});

const ProgressTrackerStats = ({items, type, maxScore}) => {
    return (
        <Layout background={colors.gradient.bg[type.toLowerCase()]}>
            <TypographySection>
                <TypographyOpaque>{type}</TypographyOpaque>
                <TypographyPercent>{items?.progress ? items.progress : 0}%</TypographyPercent>
            </TypographySection>
            <StatLines>
                {items?.scores?.map((item, index) => (
                    <StatLine
                        key={index}
                        sx={{
                            background: colors.gradient.line[type.toLowerCase()],
                            height: `${(item.result / maxScore) * 100 ?? 0}%`,
                        }}
                    />
                ))}
            </StatLines>
        </Layout>
    );
};

export default ProgressTrackerStats;
