import { axiosInstance } from './axiosInstance';

export const getUserBehaviors = (userId) =>
    axiosInstance.get('user/get_value_list', {
        params: {
            user: userId,
        },
    });

export const getFeedbackValuesData = (user, from) => {
    if (from) {
        return axiosInstance.get('user/get_value_list_with_feedback', {
            params: {
                user: user || localStorage.getItem('user-id'),
                from: from,
            },
        });

    } else {
        return axiosInstance.get('user/get_value_list_with_feedback', {
            params: {
                user: user || localStorage.getItem('user-id'),
            },
        });

    }
}

    

export const getAvatar = () => axiosInstance.get('user/get_avatar/');

export const updateAvatar = (data) => axiosInstance.post('user/update_avatar/', data);
